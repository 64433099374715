.app {
  background-color: #73C2FB;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-size: 40px 40px;
  background-image:
    linear-gradient(to right, #E0FFFF 1px, transparent 1px),
    linear-gradient(to bottom, #E0FFFF 1px, transparent 1px);

  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;

}

.link {
  color: #61dafb;
}

.section {
  display: flex;
}

.text-blurb {
  font-size: 1.5em;
  color: white;
  margin: 10px;
  padding: 20px;
  max-width: 40vw;
  background-color: rgba(255, 255, 255, 0.5);
}

.section-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;  
  flex-wrap: wrap;
}
/* 
.section-header {
  display: flex;
  flex-basis: 100%;
} */

.section-header {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
  background-color: #61dafb;
  color: white;
  font-size: 2em;
  padding: 10px;
  margin: 10px;
}

.app h2 {
  align-self: flex-start;
  margin-left: 3em;
}